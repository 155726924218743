import React from 'react';
import Link from '@components/common/link';
import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
} from 'react-icons/fa';

// can't find ts types in the library
// @ts-ignore

interface Props {}

const FooterLinks = ({}: Props) => {
  return (
    <div className="flex flex-col pb-10 lg:pb-0">
      <div className="flex flex-col">
        <div className="flex items-center mt-12 gap-x-12 self-center">
          <Link
            className="text-white"
            href="https://www.instagram.com/beselfmade"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram className="h-8 w-8" />
          </Link>
          <Link
            className="text-white"
            href="https://www.facebook.com/tryselfmade"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare className="h-8 w-8" />
          </Link>
          <Link
            className="text-white"
            href="https://www.pinterest.com/brit/_created"
            target="_blank"
            rel="noreferrer"
          >
            <FaPinterest className="h-8 w-8" />
          </Link>
          <Link
            className="text-white"
            href="https://www.linkedin.com/company/tryselfmade/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin className="h-8 w-8" />
          </Link>
        </div>
        <div className="mt-4 self-center">
          <span className="text-14 font-secondary font-regular antialiased lg:text-small">
            ©{new Date().getFullYear()} Brit Media, Inc.
          </span>
        </div>
      </div>
    </div>
  );
};

export default FooterLinks;
