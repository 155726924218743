import React, { Fragment, useState } from 'react';
import classNames from 'classnames';

import Modal from './modal';

interface ModalProps {
  title: string;
  children: any;
  className?: string;
  buttonText: string;
  actionText: React.ReactNode;
  showCloseBtn?: boolean;
  modalContainerClasses?: string;
  titleClasses?: string;
}

const ModalWithActivationButton = (props: ModalProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Fragment>
      <Modal open={open} setOpen={setOpen} {...props} />

      <div>
        <button
          onClick={() => setOpen(true)}
          className={classNames(
            'font-secondary cursor-pointer antialiased flex items-center justify-center gap-x-2 text-white font-medium hover:text-[#B9BCDF] focus:outline-topaz',
            props?.className,
          )}
        >
          {props.actionText}
        </button>
      </div>
    </Fragment>
  );
};

export default ModalWithActivationButton;
