import classNames from 'classnames';
import React, { useState, useEffect, useRef } from 'react';
import Link from '@components/common/link';
import Logo from '@components/common/logo';
import Button from '../button';
import Spinner from '@components/common/spinner';

// can't find ts types in the library
// @ts-ignore
// prettier-ignore
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

import FooterLinks from './footer-links';
import HamburgerButton from './hamburger-button';
import { Transition } from '@headlessui/react';
import { BiChevronDown } from 'react-icons/bi';
import Image from 'next/legacy/image';
import { useUser } from '@utilities/frontend/useUser';
import NotificationsBell from './notifications-bell';
import { useWindowSize } from '@utilities/frontend/use-window-size';
import { useRouter } from 'next/router';
import UpgradeModal from '../upgrade-modal';
import { WiStars } from 'react-icons/wi';
import CircleLink from '@components/common/circle-link';
import Config from '../config';
import { ADMINS_EMAILS } from '@common/config';
import {
  showFundingResourcesSection,
  showTemplatesSection,
  showVideosSection,
} from '@utilities/plans-and-permissions/helpers';

interface Props {}

const LoggedInNav = ({}: Props) => {
  const menuRef = useRef<HTMLDivElement>(null);
  const router = useRouter();
  const [expandMobileMenu, setExpandMobileMenu] = useState(false);
  const toggleMenu = () => setExpandMobileMenu(!expandMobileMenu);

  useEffect(() => {
    expandMobileMenu
      ? disableBodyScroll(menuRef.current)
      : clearAllBodyScrollLocks();
  }, [expandMobileMenu]);

  const { signOut, user } = useUser();
  const { isDesktop } = useWindowSize();
  const isAdmin = ADMINS_EMAILS.includes(user?.email || '');
  router.events?.on('routeChangeStart', () => setExpandMobileMenu(false));

  useEffect(() => {
    if (isDesktop) {
      setExpandMobileMenu(false);
    }
  }, [isDesktop]);
  return (
    <nav
      className={classNames(
        'flex flex-col lg:items-center z-50 bg-royal-blue-dark lg:h-[75px] text-white lg:p-6 lg:px-14 transition-[height]',
        expandMobileMenu
          ? 'min-h-screen absolute inset-0 w-screen overflow-auto'
          : 'h-[75px]',
      )}
    >
      <div
        className={`flex flex-col lg:flex-row lg:items-center lg:w-full pt-5 px-4 lg:p-0 min-h-full`}
      >
        <div
          className="flex flex-wrap lg:flex-nowrap lg:flex-row items-center lg:w-full"
          ref={menuRef}
        >
          <Link className="text-white " href="/">
            <Logo className="hover:text-white w-[120px] h-[23px]" />
          </Link>
          {(isDesktop || expandMobileMenu) && <MainLinkMenu />}
          <div className="order-2 lg:order-3 flex items-center gap-x-5 ml-auto">
            <NotificationsBell />
            <UserDropdown />
            <HamburgerButton
              expanded={expandMobileMenu}
              onClick={toggleMenu}
              signedIn={true}
            />
          </div>
        </div>

        {expandMobileMenu && (
          <div>
            <div className="flex justify-center items-center mt-12 gap-x-8 lg:mt-0.5 lg:gap-x-4 xl:gap-x-8">
              <div className="lg:hidden flex items-center space-x-5">
                {isAdmin && (
                  <>
                    <Link
                      href="/admin/subscriptions"
                      className="text-white text-16 font-medium pr-5 border-r border-white"
                    >
                      Admin: Subscriptions statistics
                    </Link>
                    <Link
                      href="/admin/events"
                      className="text-white text-16 font-medium pr-5 border-r border-white"
                    >
                      Admin: Events
                    </Link>
                    <Link
                      href="/admin/users"
                      className="text-white text-16 font-medium pr-5 border-r border-white"
                    >
                      Admin: Users
                    </Link>
                    <Link
                      href="/admin/mentors"
                      className="text-white text-16 font-medium pr-5 border-r border-white"
                    >
                      Admin: Mentors
                    </Link>
                  </>
                )}
              </div>
            </div>

            <div className="flex justify-center items-center mt-12 gap-x-8 lg:mt-0.5 lg:gap-x-4 xl:gap-x-8">
              <div className="lg:hidden flex items-center space-x-5">
                <Link
                  href="/settings"
                  className="text-white text-16 font-medium pr-5 border-r border-white"
                >
                  Settings
                </Link>
                <button
                  className="text-white font-medium  lg:w-max hover:underline"
                  onClick={signOut}
                >
                  Sign out
                </button>
              </div>
            </div>
            <div className="w-full lg:hidden">
              <FooterLinks />
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default LoggedInNav;

const MainLinkMenu = () => {
  const [showResourcesDropdown, setShowResourcesDropdown] = useState(false);

  return (
    <div
      className={`order-3 lg:order-2 flex flex-col w-full justify-center lg:justify-start text-center items-center py-8 text-[20px] lg:text-14 mt-8 lg:ml-12 gap-y-6 lg:gap-y-2 lg:flex-row lg:mt-0.5 lg:gap-x-5 xl:gap-x-6`}
    >
      <Link className="text-white font-medium" href="/">
        Dashboard
      </Link>
      <Link className="text-white font-medium" href="/courses">
        Courses
      </Link>

      <CircleLink
        target="_blank"
        rel="noreferrer"
        className="text-white font-medium"
        href="https://selfmade.circle.so/"
      >
        Community
      </CircleLink>

      <Link className="text-white font-medium" href="/events">
        Calendar
      </Link>
      <div
        className="text-white font-medium relative"
        onMouseLeave={() => setShowResourcesDropdown(false)}
        onMouseEnter={() => setShowResourcesDropdown(true)}
      >
        <div className="">
          <Link
            href="/resources"
            className="flex items-center justify-center lg:py-7"
          >
            <span
              className={`ml-4 lg:ml-0  justify-center ${
                showResourcesDropdown ? 'text-[#B9BCDF]' : 'text-white'
              }  `}
            >
              Resources{' '}
            </span>
            <BiChevronDown
              className={`w-4 h-4 inline-block text-white ${
                showResourcesDropdown ? 'rotate-180 text-royal-blue' : ''
              }`}
            />
          </Link>
          <ResourcesDropdown show={showResourcesDropdown} />
        </div>
      </div>

      <Link className="text-white font-medium" href="/mentors">
        Mentorship
      </Link>

      <Link
        className="text-white font-medium"
        target="_blank"
        rel="noreferrer"
        href={Config.faqLink}
      >
        Support
      </Link>
    </div>
  );
};

const UserDropdown = () => {
  const [showAvatarDropdown, setShowAvatarDropdown] = useState(false);
  const toggleDropdown = () => setShowAvatarDropdown(!showAvatarDropdown);
  const { user, isLoading } = useUser();
  const isAdmin = ADMINS_EMAILS.includes(user?.email || '');
  if (isLoading) return <Spinner />;
  return (
    <div
      className="hidden lg:flex relative items-center cursor-pointer"
      onClick={toggleDropdown}
    >
      <div className="relative w-10 h-10">
        <Image
          className="rounded-full"
          src={user?.avatar_url || '/images/templatesThumbnail.png'}
          alt={'Avatar'}
          layout="fill"
          objectFit="cover"
        />
      </div>
      <BiChevronDown
        className={`w-4 h-4 inline-block text-white ${
          showAvatarDropdown ? 'rotate-180 ' : ''
        }`}
      />
      <AvatarDropdown show={showAvatarDropdown} isAdmin={isAdmin} />
    </div>
  );
};

const ResourcesDropdown = ({ show = false }) => {
  const { user } = useUser();
  const videosAvailable = showVideosSection(user?.subscription?.tier);
  const templateshipAvailable = showTemplatesSection(user?.subscription?.tier);
  const fundingAvailable = showFundingResourcesSection(
    user?.subscription?.tier,
  );

  return (
    <Transition
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      className="lg:absolute flex flex-col gap-y-3  lg:items-start top-[-0.1rem] left-0  drop-shadow-lg transform lg:translate-y-1/2 z-[60] bg-[#454E9A] lg:w-max lg:px-5 pt-3 pb-4 lg:py-3"
      show={show}
    >
      <ResourcesLink
        label="Videos"
        href="/resources/videos"
        available={videosAvailable}
        useButton={false}
      />
      <ResourcesLink
        label="Templates"
        href="/resources/templates"
        available={templateshipAvailable}
        useButton={false}
      />
      <ResourcesLink
        label="Funding"
        href="/resources/funding-opportunities"
        available={fundingAvailable}
        useButton={false}
      />
    </Transition>
  );
};

const AvatarDropdown = ({ show = false, isAdmin = false }) => {
  const { signOut } = useUser();
  return (
    <Transition
      enter="transition duration-100 ease-out"
      enterFrom="transform scale-95 opacity-0"
      enterTo="transform scale-100 opacity-100"
      leave="transition duration-75 ease-out"
      leaveFrom="transform scale-100 opacity-100"
      leaveTo="transform scale-95 opacity-0"
      className="lg:absolute flex flex-col gap-y-3 drop-shadow-lg lg:items-start top-[-48px] left-0 lg:-translate-x-1/2 transform lg:translate-y-1/2 z-[60] bg-[#454E9A] lg:w-max lg:px-5 py-3"
      show={show}
    >
      <Link
        className="text-white font-medium w-screen lg:w-max"
        type="tertiary"
        href="/settings"
      >
        Settings
      </Link>

      {isAdmin && (
        <>
          <Link
            href="/admin/subscriptions"
            type="tertiary"
            className="text-white font-medium w-screen lg:w-max"
          >
            Admin: Subscriptions
          </Link>
          <Link
            href="/admin/events"
            type="tertiary"
            className="text-white font-medium w-screen lg:w-max"
          >
            Admin: Events
          </Link>
          <Link
            href="/admin/users"
            type="tertiary"
            className="text-white font-medium w-screen lg:w-max"
          >
            Admin: Users
          </Link>

          <Link
            href="/admin/mentors"
            type="tertiary"
            className="text-white font-medium w-screen lg:w-max"
          >
            Admin: Mentors
          </Link>
        </>
      )}
      <button
        className="text-white font-medium w-screen lg:w-max hover:underline"
        onClick={signOut}
      >
        Sign out
      </button>
    </Transition>
  );
};

const ResourcesLink = ({
  label,
  href,
  available = true,
  useButton = true,
}: {
  label: string;
  href: string;
  available: boolean;
  useButton?: boolean;
}) => {
  return available ? (
    useButton ? (
      <Button
        className="text-white font-medium w-screen lg:w-max text-[24px] lg:text-[14px] !bg-transparent hover:underline !p-0 !leading-[24px]"
        size="header"
        href={href}
      >
        {label}
      </Button>
    ) : (
      <Link
        className="text-white font-medium w-screen lg:w-max "
        type="tertiary"
        href={href}
      >
        {label}
      </Link>
    )
  ) : (
    <UpgradeModal>
      <Button
        className="text-white font-medium w-screen lg:w-max text-[24px] lg:text-[14px] !bg-transparent hover:underline !p-0 !leading-[24px]"
        size="header"
        href={undefined}
      >
        <WiStars className="w-6 h-6 -my-2" /> {label}
      </Button>
    </UpgradeModal>
  );
};
