import React from 'react';
import LoggedInNav from './nav/logged-in-nav';
import LoggedOutNav from './nav/logged-out-nav';

interface Props {
  user: any;
}

const Nav = ({ user }: Props) => {
  return !!user ? <LoggedInNav /> : <LoggedOutNav />;
};

export default Nav;
