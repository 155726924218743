import { useEffect, useState } from 'react';
import type { AppProps } from 'next/app';
import Script from 'next/script';
import Head from 'next/head';
import { IconContext } from 'react-icons';
import { createPagesBrowserClient } from '@supabase/auth-helpers-nextjs';
import { SessionContextProvider } from '@supabase/auth-helpers-react';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import NextNProgress from 'nextjs-progressbar';

import { UserProvider as DetailedUserProvider } from '@utilities/frontend/useUser';
import '../styles/globals.css';
import Layout from '@components/common/layout';
import ErrorBoundary from '@components/common/error-boundary';
import { isDev } from '@utilities/frontend/helpers';

declare global {
  interface Window {
    dataLayer: Record<string, any>[];
  }
}

// const initBotpress = () => {
//   (window as any).botpressWebChat.init({
//     composerPlaceholder: 'Chat with bot',
//     botConversationDescription:
//       'This chatbot was built surprisingly fast with Botpress',
//     botId: 'bfa40832-c203-4ca6-b826-df128e5d467e',
//     hostUrl:
//       'https://studio.botpress.cloud/bfa40832-c203-4ca6-b826-df128e5d467e/flows/wf-main',
//     messagingUrl: 'https://messaging.botpress.cloud',
//     // clientId: '<YOUR_CLIENT_ID>',
//   });
// };

function MyApp({ Component, pageProps }: AppProps) {
  const isDevEnv = isDev();

  // Google tag manager integration
  const gtmId = process.env.NEXT_PUBLIC_GTAG || '';
  useEffect(() => {
    if (!isDevEnv) {
      const tagManagerArgs: TagManagerArgs = {
        gtmId,
      };
      TagManager.initialize(tagManagerArgs);
    }
  }, [gtmId, isDevEnv]);

  const [supabaseClient] = useState(() => createPagesBrowserClient());
  const AnyComponent = Component as any;
  const ErrorBoundaryAny = ErrorBoundary as any;

  return (
    <>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>

      <Script
        id="rewardful"
        dangerouslySetInnerHTML={{
          __html: `(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`,
        }}
      />
      <Script async src="https://r.wdfl.co/rw.js" data-rewardful="bb01ea" />

      <NextNProgress color="#CFE1E6" options={{ showSpinner: false }} />
      <SessionContextProvider
        supabaseClient={supabaseClient}
        initialSession={pageProps.initialSession}
      >
        <DetailedUserProvider supabaseClient={supabaseClient}>
          <IconContext.Provider
            value={{ className: 'fill-current min-w-4 min-h-4' }}
          >
            {!isDevEnv && (
              <>
                <Script
                  id="fb-pixel"
                  strategy="afterInteractive"
                  dangerouslySetInnerHTML={{
                    __html: `
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', ${process.env.FB_PIXEL_ID});
                  fbq('track', 'PageView');
                `,
                  }}
                />

                <Script
                  src={
                    'https://www.googletagmanager.com/gtag/js?id=G-D8795G1Y2E'
                  }
                  strategy="afterInteractive"
                />

                <Script id="google-analytics" strategy="afterInteractive">
                  {`
                  console.log('initialize GTAG')
                  window.dataLayer = window.dataLayer || [];
                  function gtag(){ window.dataLayer.push(arguments); }
                  gtag('js', new Date());
                  gtag('config', 'G-D8795G1Y2E');
                  window.gtag = gtag
                `}
                </Script>
              </>
            )}

            <ErrorBoundaryAny>
              <Layout>
                <AnyComponent {...pageProps} />
              </Layout>
            </ErrorBoundaryAny>
          </IconContext.Provider>
        </DetailedUserProvider>
      </SessionContextProvider>
      {/*
      <Script src="https://cdn.botpress.cloud/webchat/v1/inject.js"></Script>
      <Script
        src="https://mediafiles.botpress.cloud/bfa40832-c203-4ca6-b826-df128e5d467e/webchat/config.js"
        defer
      ></Script> */}
    </>
  );
}

export default MyApp;
