import React, { ReactNode } from 'react';
import Head from 'next/head';
import { MetadataModel } from '@models/metadata-model';
import Metadata from '@components/common/metadata';
import Nav from '@components/common/nav';
import Footer from '@components/common/footer';
import { useRouter } from 'next/router';
import capitalize from '@utilities/capitalize';
import { useUser } from '@utilities/frontend/useUser';

interface Props {
  metadata?: MetadataModel;
  children?: ReactNode;
}

const customLayout = [
  '/overview',
  '/sign-in',
  '/signup/index',
  '/signup/second-step',
  '/signup/pricing-step',
  '/signup/payment-step',
  '/signup/success-step',
  '/members',
  '/reset-password',
  '/set-new-password',
  '/mentors',
  '/mentors/[id]',

  '/one-to-one-sessions/my',
];

const Layout = ({ children, metadata }: Props) => {
  const { pathname } = useRouter();

  const { user } = useUser();
  const [, page] = pathname.split('/').map(capitalize);
  const title = [metadata?.title || page, 'Selfmade'].join(' | ');

  const basicLayout = !customLayout.includes(pathname);

  return (
    <div>
      <Head>
        <title>{title}</title>
        {metadata && <Metadata metadata={metadata} />}
        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href="/apple-touch-icon.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="32x32"
          href="/favicon-32x32.png"
        />
        <link
          rel="icon"
          type="image/png"
          sizes="16x16"
          href="/favicon-16x16.png"
        />
        <link rel="manifest" href="/site.webmanifest" />
        <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#201c70" />
        <meta name="msapplication-TileColor" content="#201c70" />
        <meta name="theme-color" content="#ffffff"></meta>
      </Head>

      {basicLayout && (
        <>
          <Nav user={user} />
          <div className="main-layout relative bg-pale-dawn flex flex-col">
            {children}
          </div>
          <Footer user={user} />
        </>
      )}

      {!basicLayout && <>{children}</>}
    </div>
  );
};

export default Layout;
