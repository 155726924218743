import React from 'react';
import { MdOutlineClose, MdOutlineMenu } from 'react-icons/md';
import Image from 'next/legacy/image';
import classNames from 'classnames';

// can't find ts types in the library
// @ts-ignore

interface Props {
  expanded: boolean;
  inverse?: boolean;
  onClick: () => void;
  signedIn?: boolean;
}

const HamburgerButton = ({
  expanded = false,
  onClick = () => {},
  inverse = false,
  signedIn = false,
}: Props) => {
  return (
    <div className="lg:hidden mr-4">
      {expanded ? (
        <MdOutlineClose
          className="cursor-pointer w-10 h-7 mr-[-30px]"
          onClick={onClick}
        />
      ) : (
        // <MdOutlineMenu className="cursor-pointer w-10 h-7" onClick={onClick} />
        <div
          className={classNames(
            'absolute',
            signedIn ? 'mt-[-8px]' : 'mt-[6px]',
            inverse ? 'aspect-[24/17] w-[24px]' : 'aspect-[25/17] w-[25px]',
          )}
          onClick={onClick}
        >
          <Image
            className=""
            src={
              inverse
                ? '/images/MobileMenuIcon-inverse.png'
                : '/images/MobileMenuIcon.png'
            }
            alt={''}
            layout="fill"
            objectFit="cover"
          />
        </div>
      )}
    </div>
  );
};

export default HamburgerButton;
