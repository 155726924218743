import classNames from 'classnames';
import React, { useState, useEffect } from 'react';
import Link from '@components/common/link';
import Logo from '@components/common/logo';
import Button from '../button';
import { MdArrowRightAlt } from 'react-icons/md';

// can't find ts types in the library
// @ts-ignore
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import FooterLinks from './footer-links';
import HamburgerButton from './hamburger-button';

interface Props {}

const LoggedOutNav = ({}: Props) => {
  const [expandMobileMenu, setExpandMobileMenu] = useState(false);
  const toggleMenu = () => setExpandMobileMenu(!expandMobileMenu);

  useEffect(() => {
    expandMobileMenu ? disableBodyScroll(document) : enableBodyScroll(document);
  }, [expandMobileMenu]);

  return (
    <nav
      className={classNames(
        'flex flex-col  z-50 bg-royal-blue-dark  text-white  transition-[height]' +
          '' +
          'lg:items-center lg:justify-center lg:h-[75px] lg:p-6 lg:px-14',
        expandMobileMenu
          ? 'h-screen absolute inset-0 w-screen'
          : 'h-[75px] overflow-hidden',
      )}
    >
      <div className="flex flex-col lg:flex-row lg:items-center lg:w-full pb-[120px] lg:pb-0 justify-between h-full">
        <div className="flex flex-col items-center lg:flex-row">
          <div className="p-6 lg:px-0 flex justify-between w-full lg:w-auto">
            <Link
              className="text-white "
              href="https://www.tryselfmade.com"
              target="_blank"
            >
              <Logo className="hover:text-white w-[120px] h-[23px]" />
            </Link>

            <HamburgerButton expanded={expandMobileMenu} onClick={toggleMenu} />
          </div>
        </div>
        <div>
          <div className="flex justify-center items-center mt-12 gap-x-8 lg:mt-0.5 lg:gap-x-10">
            <>
              <Link className="text-white text-14 font-medium" href="/sign-in">
                Sign In
              </Link>
              <div className="flex flex-wrap items-center space-y-2 space-x-1 lg:space-x-3">
                <Button
                  size="header"
                  className="!text-royal-blue"
                  secondary
                  href="/signup"
                  target="_self"
                >
                  Get Started <MdArrowRightAlt />
                </Button>
              </div>
            </>
          </div>
          <div className="w-full lg:hidden">
            <FooterLinks />
          </div>
        </div>
      </div>
    </nav>
  );
};

export default LoggedOutNav;
