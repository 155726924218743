import React from 'react';
import classNames from 'classnames';
import { default as NextLink } from 'next/link';
import PropTypes from 'prop-types';

const noop = () => {};

interface Props {
  className?: string;
  size?: 'sm' | 'md' | 'lg' | 'header' | 'link';
  onClick?: any;
  disabled?: boolean;
  secondary?: boolean;
  children?: React.ReactNode;
  isLink?: boolean;
  href?: string;
  rel?: string;
  target?: string;
  useDefaultBgColor?: boolean;
  type?: 'button' | 'submit' | 'reset' | undefined;
}

const Button = ({
  className = '',
  size = 'sm',
  onClick = noop,
  disabled = false,
  secondary = false,
  href = '',
  target = '_blank',
  rel = 'noreferrer',
  useDefaultBgColor = true,
  children,
  type,
}: Props) => {
  const buttonClass = classNames(
    `flex items-center justify-center gap-x-2
    text-white font-secondary font-semibold rounded-sm
    focus:outline-topaz border-grey-light disabled:border disabled:text-grey-dark antialiased min-w-[44px]`,
    useDefaultBgColor
      ? 'hover:bg-royal-blue-light disabled:bg-grey-lighter active:bg-royal-blue-dark'
      : '',
    {
      'text-small leading-[12px] px-3 py-[10px]': size === 'sm',
      'text-body leading-[16px] px-4 py-3': size === 'md',
      'text-body leading-[16px] p-4': size === 'lg',
      'text-header leading-[14px] p-[12px]': size === 'header',
      'bg-white text-charcoal hover:bg-pale-dawn active:bg-pale-dawn-dark':
        secondary && useDefaultBgColor,
      'bg-royal-blue': !secondary && useDefaultBgColor,
      'py-2 group text-body cursor-pointer': size === 'link',
    },
    className,
  );

  if (href) {
    const external = href.includes('https://') || href.includes('http://');
    if (external) {
      return (
        <a
          href={disabled ? '' : href}
          className={buttonClass}
          target={target}
          rel={rel}
          onClick={onClick}
        >
          {children}
        </a>
      );
    } else {
      return (
        <NextLink
          href={disabled ? '' : href}
          className={buttonClass}
          onClick={onClick}
          target={target}
          rel={rel}
        >
          {children}
        </NextLink>
      );
    }
  }

  return (
    <button
      className={buttonClass}
      onClick={onClick}
      disabled={disabled}
      type={type}
    >
      {children}
    </button>
  );
};

Button.defaultProps = {
  __TYPE: 'Button',
};

export default Button;
