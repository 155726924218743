import classNames from 'classnames';
import { Popover, Transition } from '@headlessui/react';
import { useState, useEffect, useRef, memo } from 'react';
import { Fragment } from 'react';
import { MdNotifications } from 'react-icons/md';
import Spinner from '@components/common/spinner';
import useSWR from 'swr';
import { formatDistanceToNow } from 'date-fns';
import Link from 'next/link';
import { GiPartyPopper } from 'react-icons/gi';
import { FundingOpportunityModel } from '@models/funding-opportunity-model';
import { RiMoneyDollarCircleFill } from 'react-icons/ri';
import { CEOReplaysModel } from '@models/ceo-replays';
import { BsFillCollectionPlayFill } from 'react-icons/bs';
import { TemplateModel } from '@models/template-model';
import { RiAttachmentFill } from 'react-icons/ri';
import { EventModel } from '@models/event-model';
import { IoMdCalendar } from 'react-icons/io';
import { CourseAnnouncementModel } from '@models/course-announcement-model';
import { IoMdBookmarks } from 'react-icons/io';
import { dequal } from 'dequal';
import { flattenDeep } from 'lodash';

interface NotificationsBellProps {
  className?: string;
}

const fetcher = (arg: any, ...args: any) =>
  fetch(arg, ...args).then((res) => res.json());

function NotificationsBell({ className }: NotificationsBellProps) {
  const oldData = useRef();
  const [unreadIndicator, setUnreadIndicator] = useState<boolean>(true);
  const {
    data: notifications,
    isValidating,
    mutate,
    error,
  } = useSWR('/api/notifications', fetcher, {
    onSuccess: (newData) => {
      const shouldUpdate = dequal(oldData.current, newData) !== true;
      oldData.current = newData;
      setUnreadIndicator(shouldUpdate);
    },
  });

  const click = () => setUnreadIndicator(false);

  return (
    <div>
      <Popover className="lg:relative h-7">
        {({ close }) => (
          <>
            <Popover.Button as={Fragment}>
              <button onClick={click} className="no-outline-on-focus">
                <div className="flex gap-x-2 relative">
                  <MdNotifications className="w-7 h-7" />
                  <UnreadNotificationsDot show={unreadIndicator} />
                </div>
              </button>
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="max-h-screen absolute top-20 lg:top-11 left-0 lg:left-full lg:translate-y-2 z-10 transform origin-top-right lg:-translate-x-full lg:px-4 w-screen lg:max-w-[410px] sm:px-0">
                <div className="overflow-hidden shadow-lg">
                  {!notifications && (
                    <div className="bg-white border-b border-b-grey-light p-10 text-royal-blue flex gap-x-2 justify-center items-center font-secondary text-14">
                      <Spinner /> Fetching updates...
                    </div>
                  )}
                  {notifications && (
                    <NotificationList {...notifications} click={close} />
                  )}
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
}
export default NotificationsBell;

const UnreadNotificationsDot = ({ show }: { show: boolean }) => {
  if (!show) return null;
  return (
    <span className="absolute top-0 right-0.5 block h-2.5 w-2.5 rounded-full ring-1 ring-royal-blue bg-burnt-orange-light" />
  );
};

interface NotificationListProps {
  courses: any[];
  funding: any[];
  replays: any[];
  templates: any[];
  events: any[];
  click: () => void;
}

const NotificationList = ({
  courses = [],
  funding = [],
  replays = [],
  templates = [],
  events = [],
  click,
}: NotificationListProps) => {
  if (
    flattenDeep([courses, funding, replays, templates, events]).length === 0
  ) {
    return <NoNotifications />;
  }
  return (
    <div className="relative bg-white text-charcoal text-16 font-secondary leading-tight pb-2 overflow-auto max-h-screen">
      <ul className="flex flex-col gap-y-2 divide-y divide-grey-light">
        {courses.map((item, i) => (
          <CourseAnnouncementNotification
            key={`notification:course:${item.id || i}`}
            {...item}
            click={click}
          />
        ))}
        {funding.map((item, i) => (
          <FundingOpportunityNotification
            key={`notification:funding:${item.id || i}`}
            {...item}
            click={click}
          />
        ))}
        {replays.map((item, i) => (
          <CEOReplayNotification
            key={`notification:replay:${item.id || i}`}
            {...item}
            click={click}
          />
        ))}
        {templates.map((item, i) => (
          <TemplateNotification
            key={`notification:template:${item.id || i}`}
            {...item}
            click={click}
          />
        ))}
        {events.map((item, i) => (
          <EventNotification
            key={`notification:event:${item.id || i}`}
            {...item}
            click={click}
          />
        ))}
      </ul>
    </div>
  );
};

const NoNotifications = () => (
  <div className="relative bg-white text-charcoal text-16 font-secondary leading-tight pb-2 overflow-auto max-h-screen pt-2">
    <ul className="flex flex-col gap-y-2 divide-y divide-grey-light">
      <li
        key={`notification:none`}
        className="flex justify-start items-start pt-4 pb-4 pl-5 pr-6 cursor-pointer"
      >
        <GiPartyPopper className="w-8 h-8 text-burnt-orange mr-3 relative top-1 flex-none" />
        <div>
          <h6 className="pt-2">No new notifications</h6>
          <p className="text-12 text-grey-dark mt-1">
            {formatDistanceToNow(new Date(), {
              addSuffix: true,
            })}
          </p>
        </div>
      </li>
    </ul>
  </div>
);

const CourseAnnouncementNotification = ({
  id,
  name,
  publishedAt,
  click,
}: CourseAnnouncementModel) => (
  <Link href={`/resources/funding-opportunities`} passHref legacyBehavior>
    <li
      key={`notification:${id}`}
      className="flex justify-start items-start pt-4 pb-4 pl-5 pr-6 cursor-pointer"
      onClick={click}
    >
      <IoMdBookmarks className="w-8 h-8 text-burnt-orange mr-3 relative top-1 flex-none" />
      <div>
        <h6 className="pt-2">
          <a className="font-semibold text-charcoal" target="_blank">
            {name}
          </a>{' '}
          course is now available!
        </h6>
        <p className="text-12 text-grey-dark mt-1">
          {formatDistanceToNow(new Date(publishedAt), {
            addSuffix: true,
          })}
        </p>
      </div>
    </li>
  </Link>
);

const FundingOpportunityNotification = ({
  id,
  name,
  publishedAt,
  click,
}: FundingOpportunityModel) => (
  <Link href={`/resources/funding-opportunities`} passHref legacyBehavior>
    <li
      key={`notification:${id}`}
      className="flex justify-start items-start pt-4 pb-4 pl-5 pr-6 cursor-pointer"
      onClick={click}
    >
      <RiMoneyDollarCircleFill className="w-8 h-8 text-burnt-orange mr-3 relative top-1 flex-none" />
      <div>
        <h6 className="pt-2">
          <a className="font-semibold text-charcoal" target="_blank">
            {name}
          </a>{' '}
          funding opportunity is now available.
        </h6>
        <p className="text-12 text-grey-dark mt-1">
          {formatDistanceToNow(new Date(publishedAt), {
            addSuffix: true,
          })}
        </p>
      </div>
    </li>
  </Link>
);

const CEOReplayNotification = ({
  id,
  title,
  publishedAt,
  click,
}: CEOReplaysModel) => (
  <Link href={`/resources/videos/${id}`} passHref legacyBehavior>
    <li
      key={`notification:${id}`}
      className="flex justify-start items-start pt-4 pb-4 pl-5 pr-6 cursor-pointer"
      onClick={click}
    >
      <BsFillCollectionPlayFill className="w-6 h-8 text-burnt-orange mr-4 relative top-1 flex-none" />
      <div>
        <h6 className="pt-2">
          <a className="font-semibold text-charcoal">{title}</a> replay is now
          available.
        </h6>
        <p className="text-12 text-grey-dark mt-1">
          {formatDistanceToNow(new Date(publishedAt), {
            addSuffix: true,
          })}
        </p>
      </div>
    </li>
  </Link>
);
const TemplateNotification = ({
  id,
  title,
  publishedAt,
  click,
}: TemplateModel) => (
  <Link href={`/resources/templates`} passHref legacyBehavior>
    <li
      key={`notification:${id}`}
      className="flex justify-start items-start pt-4 pb-4 pl-5 pr-6 cursor-pointer"
      onClick={click}
    >
      <RiAttachmentFill className="w-7 h-8 text-burnt-orange mr-4 relative top-1 flex-none" />
      <div>
        <h6 className="pt-2">
          <a className="font-semibold text-charcoal">{title}</a> template is now
          available.
        </h6>
        <p className="text-12 text-grey-dark mt-1">
          {formatDistanceToNow(new Date(publishedAt), {
            addSuffix: true,
          })}
        </p>
      </div>
    </li>
  </Link>
);
const EventNotification = ({ id, title, start, click }: EventModel) => (
  <Link href={`/events/${id}`} passHref legacyBehavior>
    <li
      key={`notification:${id}`}
      className="flex justify-start items-start pt-4 pb-4 pl-5 pr-6 cursor-pointer"
      onClick={click}
    >
      <IoMdCalendar className="w-8 h-8 text-burnt-orange mr-4 relative top-1 flex-none" />
      <div>
        <h6 className="pt-2">
          <a className="font-semibold text-charcoal">{title}</a> is coming up
          soon.
        </h6>
        <p className="text-12 text-grey-dark mt-1">
          {formatDistanceToNow(new Date(start), {
            addSuffix: true,
          })}
        </p>
      </div>
    </li>
  </Link>
);
