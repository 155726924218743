import { createContext, useContext } from 'react';
import {
  useUser as useSupabaseUser,
  User,
  useSessionContext,
} from '@supabase/auth-helpers-react';
import { SupabaseClient } from '@supabase/auth-helpers-nextjs';
import useSWR from 'swr';
import { useRouter } from 'next/router';

export interface Subscription {
  tier: string;
  status: string;
  plan: string;
  currency: string;
  interval: string;
  amount: number | null;
  nextPayment: number | null;
  cancel_at: number | null;
  productName: string;
  productId: string;
}

// Makin' a customized UserProvider, with extra details for the useUser hook
export interface UserDetails extends User {
  display_name: string | null;
  first_name: string | null;
  avatar_url: string | null;
  circle_id: string | null;
  thinkific_id: string | null;
  stripe_customer_id: string | null;
  business_stage: string | null;
  industry: string | null;
  receive_marketing_emails: string | null;
  subscription: Subscription | null;
  onboarding_completion_step: number | null;
  circle_confirmation?: string | null;
}

type UserContextType = {
  user: UserDetails | null;
  accessToken: string | undefined;
  session?: any | null;
  error?: Error | unknown;
  isLoading: boolean;
  refreshUser: () => void;
  signOut: (event: any) => void;
};

const userFetcher = async (url: string) => {
  const response = await fetch(url);
  return response.ok
    ? response.json()
    : {
        success: false,
        user: null,
        accessToken: null,
        error: response.statusText,
      };
};

export const UserContext = createContext<UserContextType | undefined>(
  undefined,
);

export interface Props {
  supabaseClient: SupabaseClient;
  [propName: string]: any;
}

export const UserProvider = (props: Props) => {
  // TODO: Make it optional??
  const router = useRouter();
  const { supabaseClient: supabase } = props;
  const user = useSupabaseUser();

  const {
    error: userError,
    isLoading: isLoadingUser,
    session,
  } = useSessionContext();

  const {
    data: userDetailsData,
    error: userDetailsError,
    mutate: refreshUser,
  } = useSWR(user ? '/api/auth/profile' : null, userFetcher);
  const { user: userDetails } = userDetailsData || {};

  const signOut = async (event: any) => {
    event.preventDefault();
    await supabase.auth.signOut();
    await router.push('/sign-in');
  };

  const value = {
    accessToken: session?.access_token,
    user: user ? { ...user, ...userDetails } : null,
    refreshUser,
    signOut,
    error: userDetailsError || userError,
    isLoading:
      isLoadingUser || user ? !userDetailsData && !userDetailsError : false,
  };

  return <UserContext.Provider value={value} {...props} />;
};

export const useUser = () => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserProvider context.`);
  }
  return context;
};
