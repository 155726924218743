export const NON_ACTIVE_SUBSCRIPTION_STATUSES = [
  'incomplete_expired',
  'canceled',
  'unpaid',
];

export const ACTIVE_SUBSCRIPTION_STATUSES = [
  'trialing',
  'free trial',
  'active',
  'incomplete', // ?? possibly be active during 24 hours
  'past_due', // ?? possibly be active for some period
];
