import { useEffect, useState } from 'react';

export function useWindowSize() {
  const [windowWidth, setWindowWidth] = useState<number | undefined>(undefined);
  const [windowHeight, setWindowHeight] = useState<number | undefined>(
    undefined,
  );
  const [windowInnerWidth, setwindowInnerWidth] = useState<number | undefined>(
    undefined,
  );

  useEffect(() => {
    if (typeof window === 'undefined') return;
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const windowIsLoaded = windowWidth !== undefined;

  function handleResize() {
    setwindowInnerWidth(window.innerWidth);
    const innerWidth = window.innerWidth >= 1920 ? 1920 : window.innerWidth;
    const innerHeight = window.innerHeight;

    setWindowWidth(innerWidth);
    setWindowHeight(innerHeight);
  }

  return {
    windowWidth,
    windowHeight,
    windowIsLoaded,
    windowInnerWidth: windowInnerWidth,
    isXlDesktop: windowIsLoaded && windowWidth! >= 1280,
    isDesktop: windowIsLoaded && windowWidth! >= 1024,
    isTablet: windowIsLoaded && windowWidth! >= 768,
  };
}
