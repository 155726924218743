import React, { MouseEventHandler } from 'react';
import classNames from 'classnames';
import { default as NextLink } from 'next/link';

export interface LinkProps {
  className?: string;
  href: string;
  target?: string;
  rel?: string;
  disabled?: boolean;
  embedded?: boolean;
  onClick?: MouseEventHandler;
  children?: React.ReactNode;
  type?: string;
  overrideClases?: boolean;
}

const Link = ({
  className = '',
  href,
  target = '_self',
  rel,
  disabled = false,
  embedded = false,
  onClick,
  children,
  type = 'primary',
  overrideClases = false,
}: LinkProps) => {
  const external = href.includes('https://') || href.includes('http://');

  const classes = classNames(
    `font-secondary cusor-pointer antialiased`,
    disabled && 'text-grey-dark pointer-events-none',
    embedded
      ? 'inline-block underline'
      : 'flex items-center justify-center gap-x-2',
    className,
    {
      'hover:text-[#B9BCDF] focus:outline-topaz ': type === 'primary',
      'text-[#535257] font-medium text-body': type === 'secondary',
      'hover:underline': type === 'tertiary',
    },
  );

  if (external) {
    return (
      <a
        href={disabled ? '' : href}
        className={overrideClases ? className : classes}
        target={target}
        rel={rel}
        onClick={onClick}
      >
        {children}
      </a>
    );
  } else {
    return (
      <NextLink
        href={disabled ? '' : href}
        className={overrideClases ? className : classes}
        target={target}
        rel={rel}
        onClick={onClick}
      >
        {children}
      </NextLink>
    );
  }
};

export default Link;
