import { ResourceModel } from '@models/resource-model';

export const ADMINS_EMAILS = [
  'skarm61@gmail.com',
  'skarm61+full@gmail.com',
  'skarm61+community@gmail.com',
  'jemal@brit.co',
  'selfmade@brit.co',
  'ana@brit.co',
  'taylor@brit.co',
  'cari@brit.co',
  'matt@brit.co',
];

export const SUBSCRIPTION_CACHE_KEY = 'customer_subscription_';

export const RESOURCES: ResourceModel[] = [
  {
    header: 'Videos',
    title: 'View All Videos',
    url: '/resources/videos',
    image: '/images/CEOResource.png',
    alt: 'Videos',
    tier: 'launch',
    description:
      "Learn directly from the world's most renowned leaders. Watch inspiring discussions to motivate your journey.",
  },
  {
    header: 'Templates',
    title: 'View All Templates',
    url: '/resources/templates',
    image: '/images/TemplatesResource.png',
    alt: 'Templates',
    tier: 'launch',
    description:
      'Browse our collection of documents and dynamic templates designed to guide your operations.',
  },
  {
    header: 'Funding Opportunities',
    title: 'View All Opportunities',
    url: '/resources/funding-opportunities',
    image: '/images/FundingOpp.png',
    alt: 'Opportunities',
    tier: 'launch',
    description:
      'Explore our curated and up-to-date funding opportunities that will keep your biz growing.',
  },
  {
    header: 'Book a Mentor',
    title: 'View All Mentors',
    url: '/mentors',
    image: '/images/MentorResource.png',
    alt: 'Mentors',
    tier: 'launch',
    description:
      'Talk shop 1:1 with our experts. These pros have been there, done that, and are here for you.',
  },
];
