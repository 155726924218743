import React from 'react';
import { MetadataModel } from '@models/metadata-model';

interface Props {
  metadata: MetadataModel;
}

const Metadata = ({ metadata }: Props) => {
  return (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport" content="initial-scale=1.0, width=device-width" />
      <meta name="description" content={metadata.description} />

      {/* Open Graph Tags */}
      <meta property="og:title" content={metadata.title} />
      <meta property="og:description" content={metadata.description} />
      {metadata.image ? (
        <meta property="og:image" content={metadata.image} />
      ) : null}
      {metadata.url ? <meta property="og:url" content={metadata.url} /> : null}
      {metadata.type ? (
        <meta property="og:type" content={metadata.type} />
      ) : null}

      {/* Favicon */}
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/apple-touch-icon.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/favicon-16x16.png"
      />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
      <meta name="msapplication-TileColor" content="#da532c" />
      <meta name="theme-color" content="#ffffff" />
      {/* End favicon */}
    </>
  );
};

export default Metadata;
