import React from 'react';
import { MdClose, MdArrowRightAlt } from 'react-icons/md';
import { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '@components/common/button';
import { IoSparklesSharp } from 'react-icons/io5';
import { mentorshipLowestTier } from '@utilities/plans-and-permissions/helpers';

// interface Price {
//   id: string;
//   interval: string;
//   nickname: string;
//   unit_amount: number;
// }

interface UpgradeButtonProps {
  children?: React.ReactNode;
  className?: string;
}

const UpgradeModal = ({ className, children }: UpgradeButtonProps) => {
  const [open, setOpen] = useState(false);
  const toggleOpen = () => setOpen(!open);

  const kids = React.Children.map(children, (child: any) => {
    if (child?.props?.__TYPE === 'Button') {
      return React.cloneElement(child, {
        onClick: toggleOpen,
      });
    } else {
      return child;
    }
  });

  return (
    <Fragment>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-[100]" onClose={setOpen}>
          <Backdrop />

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              {/* This element is to trick the browser into centering the modal contents. */}
              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>

              <UpgradePanel close={toggleOpen} />
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {kids}
    </Fragment>
  );
};

export default UpgradeModal;

const Backdrop = () => (
  <Transition.Child
    as={Fragment}
    enter="ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div className="fixed h-screen w-screen inset-0 bg-charcoal/75 transition-opacity" />
  </Transition.Child>
);

interface PanelProps {
  close: () => void;
}

const UpgradePanel = ({ close }: PanelProps) => {
  const requiredTier = mentorshipLowestTier;
  return (
    <Transition.Child
      as={Fragment}
      enter="ease-out duration-300"
      enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
      enterTo="opacity-100 translate-y-0 sm:scale-100"
      leave="ease-in duration-200"
      leaveFrom="opacity-100 translate-y-0 sm:scale-100"
      leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    >
      <Dialog.Panel className="relative inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-2 sm:px-12 sm:pt-8 sm:pb-8">
        <div>
          <button onClick={close} className="absolute top-3 right-3">
            <MdClose className="h-8 w-8" />
          </button>
          <div className="mt-3 text-center sm:mt-7">
            <Dialog.Title
              as="h3"
              className="font-bold leading-tight text-title w-full ml-2 mb-4 flex gap-x-2 justify-center items-center"
            >
              <IoSparklesSharp className="text-burnt-orange" /> Upgrade Required
            </Dialog.Title>
            <div className="max-w-lg mx-auto mt-2 font-secondary text-14 sm:text-16">
              This area requires a{' '}
              <strong className="font-semibold">{requiredTier}</strong> or
              higher membership. Want access? Upgrade your plan! Click the
              button below to start your 7-day free trial.
            </div>
          </div>
        </div>
        <div className="mt-5 sm:mb-2 sm:mt-6 flex gap-4 justify-center w-full flex-wrap sm:flex-nowrap">
          <Button size="lg" href="/settings/change-plan">
            Get access <MdArrowRightAlt />
          </Button>
        </div>
      </Dialog.Panel>
    </Transition.Child>
  );
};
