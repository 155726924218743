import React from 'react';
import Link from '@components/common/link';
import { useUser } from '@utilities/frontend/useUser';
import { LinkProps } from '@components/common/link';
import ModalWithActivationButton from './modal-with-activation-button';
interface CircleLinkProps extends LinkProps {
  href: string;
  children: React.ReactNode;
  className?: string;
}

const CircleLink = ({
  href,
  children,
  className,
  ...props
}: CircleLinkProps) => {
  const { user } = useUser();
  const noCircle = !user?.circle_id || !user.circle_confirmation;
  if (noCircle) {
    return (
      <ModalWithActivationButton
        title="Please accept your invitation to Circle"
        buttonText="Got it, I'll check"
        actionText={children}
        className={className}
      >
        You should have received an email from Selfmade inviting you to join our
        community on Circle — please check your inbox to accept it.
      </ModalWithActivationButton>
    );
  }
  return (
    <Link href={href} className={className} {...props}>
      {children}
    </Link>
  );
};
export default CircleLink;
