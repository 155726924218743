import { TiersMap } from '@models/generic-model';
import { NON_ACTIVE_SUBSCRIPTION_STATUSES } from '@utilities/constants';
import { Subscription } from '@utilities/frontend/useUser';

// ------------------------------------- Config start

export const tiersMap: TiersMap = {
  free: 0,
  launch: 1,
  grow: 2,
  scale: 3,
  platinum: 4,
};

export const tiersMapNew: TiersMap = {
  free: 0, // actually just a fallback
  community: 1,
  full: 2,
};

const eventTiersMapping = {
  launch: ['launch', 'grow', 'scale', 'full'],
  community: ['launch', 'grow', 'scale', 'community', 'full'],
  full: ['launch', 'grow', 'scale', 'full'],
};

const EVENTS_AVAILABLE_FOR_LAUNCH_TIER = ['Onboarding', 'Interview'];
const AVAILABLE_TIERS_TO_SUBSCRIBE = [
  // 'launch',
  // 'grow',
  // 'scale',
  'community',
  'full',
];
const AVAILABLE_PERIODS_TO_SUBSCRIBE = ['monthly', 'yearly'];

const SCHOLARSHIP_COUPON_IDS = [
  'Z4u8W9YM',
  'BchPqT1B',
  'ij6Sxcbf',
  'wiSPG5X9',
  'jlgdjmR3', //TEST COUPON!
];

// ------------------------------------- Config end

export const planNameToTier = (planName: string): string => {
  return planName
    ?.toLowerCase()
    .replace('selfmade ', '')
    .replace(' monthly', '')
    .replace(' yearly', '')
    .replace(' membership', '')
    .replace('+', '')
    .replace(' (20% off)', '')
    .trim();
};

export const planNameToTierName = (planName: string): string => {
  return planName
    ?.replace(' Monthly', '')
    .replace('Selfmade ', '')
    .replace(' Yearly', '')
    .replace(' yearly', '')
    .replace(' monthly', '')
    .replace(' Membership', '')
    .replace(' (20% off)', '')
    .trim();
};

export const tierHigherOrEqualTarget = (
  tier = 'free',
  targetTierOld = 'free',
  targetTierNew = 'free',
): boolean => {
  if (userOnNewPlan(tier)) {
    const higher = tiersMapNew[tier] >= tiersMapNew[targetTierNew];
    return higher;
  } else {
    const higher = tiersMap[tier] >= tiersMap[targetTierOld];
    return higher;
  }
};

export const showMentorshipSection = (tier?: string): boolean => {
  let lowestTierOld = 'grow';
  let lowestTierNew = 'full';
  return tierHigherOrEqualTarget(tier, lowestTierOld, lowestTierNew);
};

export const showVideosSection = (tier?: string): boolean => {
  if (!userOnNewPlan(tier)) {
    return true;
  }
  let lowestTierOld = 'grow';
  let lowestTierNew = 'full';
  return tierHigherOrEqualTarget(tier, lowestTierOld, lowestTierNew);
};

export const showTemplatesSection = (tier?: string): boolean => {
  if (!userOnNewPlan(tier)) {
    return true;
  }
  let lowestTierOld = 'grow';
  let lowestTierNew = 'full';
  return tierHigherOrEqualTarget(tier, lowestTierOld, lowestTierNew);
};

export const showFundingResourcesSection = (tier?: string): boolean => {
  if (!userOnNewPlan(tier)) {
    return true;
  }
  let lowestTierOld = 'grow';
  let lowestTierNew = 'full';
  return tierHigherOrEqualTarget(tier, lowestTierOld, lowestTierNew);
};

export const userHasAccessToEvent = (
  subscription: any,
  event: any,
): boolean => {
  const newTier: string = event?.tiers?.toLowerCase() || 'launch';
  const availablePlans =
    eventTiersMapping[newTier as keyof typeof eventTiersMapping];
  return availablePlans?.includes(subscription?.tier || 'launch');
  // const mapOldEventTierToNew = {
  //   launch: 'community',
  //   grow: 'full',
  //   scale: 'full',
  // } as any;

  // TODO: need to test it
  // const newTier = event?.tiers?.toLowerCase() || 'launch';
  // const mappedToNewTier = mapOldEventTierToNew[newTier];
  // return tierHigherOrEqualTarget(
  //   subscription?.tier,
  //   event?.tiers?.toLowerCase(),
  //   mappedToNewTier,
  // );
};

export const userHasAccessToCourse = (subscription: any): boolean => {
  return userOnOldPlan(subscription?.tier) || subscription?.tier === 'full';
};

export const userOnNewPlan = (tier?: string): boolean => {
  return Object.keys(tiersMapNew).includes(tier || 'community');
};

export const userOnOldPlan = (tier?: string): boolean => {
  return !userOnNewPlan(tier);
};

export const userHasAccessToResource = (
  lowestTier: string,
  subscription: any,
): boolean => {
  const tier = subscription?.tier || 'free';
  return tierHigherOrEqualTarget(tier, lowestTier, 'full');
};

// export const subscriptionTierHigherOrEquual = (
//   subscription: any,
//   tier: string,
// ): boolean => {
//   return tierHigherOrEqualTarget(subscription?.tier, tier.toLowerCase());
// };

export const limitEventsForUser = (tier?: string): string[] => {
  if (tier === 'launch') {
    return EVENTS_AVAILABLE_FOR_LAUNCH_TIER;
  } else {
    return [];
  }
};

export const userCanChangeTier = (
  tier: string,
  period: string,
  subscription: any,
): boolean => {
  if (!AVAILABLE_TIERS_TO_SUBSCRIBE.includes(tier)) {
    return false;
  }

  if (!AVAILABLE_PERIODS_TO_SUBSCRIBE.includes(period)) {
    return false;
  }

  if (NON_ACTIVE_SUBSCRIPTION_STATUSES.includes(subscription.status)) {
    return true;
  }

  if (tier === subscription.tier && period === subscription.interval) {
    return false;
  }

  return true;
};

export const mentorshipLowestTier = 'Full';

export const isScholarshipMember = (subscription: any): boolean => {
  return SCHOLARSHIP_COUPON_IDS.includes(subscription?.discount?.coupon?.id);
};
