import React, { Fragment, ReactNode } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { MdClose } from 'react-icons/md';

import Button from '@components/common/button';

interface ModalProps {
  title: string;
  titleClasses?: string;

  children: any;

  // className?: string;
  // actionText: React.ReactNode;
  buttonText?: string;
  showCloseBtn?: boolean;
  modalContainerClasses?: string;

  open: boolean;
  setOpen: any;
}

const Modal = ({ open, setOpen, ...props }: ModalProps) => {
  const toggleOpen = () => setOpen(!open);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <Backdrop />

        <div className="fixed z-50 inset-0 overflow-y-auto">
          <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            {/* This element is to trick the browser into centering the modal contents. */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <ModalPanel
              close={toggleOpen}
              title={props.title}
              body={
                typeof props.children === 'function'
                  ? props.children({
                      close: () => setOpen(false),
                    })
                  : props.children
              }
              buttonText={props.buttonText}
              showCloseBtn={props.showCloseBtn === false ? false : true}
              modalContainerClasses={props.modalContainerClasses}
              titleClasses={props.titleClasses}
            />
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default Modal;

const Backdrop = () => (
  <Transition.Child
    as={Fragment}
    enter="ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div className="fixed h-screen w-screen inset-0 bg-charcoal/75 transition-opacity" />
  </Transition.Child>
);

interface ModalPanelProps {
  title: string;
  body: ReactNode;
  buttonText?: string;
  showCloseBtn: boolean;
  modalContainerClasses?: string;
  titleClasses?: string;
  close: () => void;
}

const ModalPanel = ({
  title,
  body,
  buttonText,
  showCloseBtn,
  modalContainerClasses,
  titleClasses,
  close,
}: ModalPanelProps) => (
  <Transition.Child
    as={Fragment}
    enter="ease-out duration-300"
    enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
    enterTo="opacity-100 translate-y-0 sm:scale-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100 translate-y-0 sm:scale-100"
    leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
  >
    <Dialog.Panel
      className={classNames(
        'relative inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left',
        'overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle',
        'sm:max-w-2xl sm:w-full sm:p-2 sm:px-12 sm:pb-8',
        modalContainerClasses || '',
      )}
    >
      <div>
        <button onClick={close} className="absolute top-3 right-3">
          <MdClose className="h-8 w-8" />
        </button>
        <div className="mt-3 text-center sm:mt-7">
          <Dialog.Title
            as="h3"
            className={classNames(
              'font-bold text-title w-full ml-2 mb-4',
              titleClasses || '',
            )}
          >
            {title}
          </Dialog.Title>
          <div className="mt-2 font-secondary text-14 sm:text-16">{body}</div>
        </div>
        {showCloseBtn && (
          <Button size="lg" className="mx-auto mt-8" onClick={close}>
            {buttonText}
          </Button>
        )}
      </div>
    </Dialog.Panel>
  </Transition.Child>
);
