import clsx, { ClassValue } from 'clsx';
import { twMerge } from 'tailwind-merge';

export const baseFetcher = (arg: any, ...args: any) =>
  fetch(arg, ...args).then((res) => res.json());

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

export function isDev(): boolean {
  return process.env.NODE_ENV === 'development';
}

export function buildURL(baseURL: string, queryParams: any) {
  const url = new URL(baseURL);
  for (const [key, value] of Object.entries(queryParams)) {
    url.searchParams.append(key, value as string);
  }
  return url.toString();
}
