import { Component } from 'react';
import { MdOutlineErrorOutline } from 'react-icons/md';
import Button from '@components/common/button';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false };
  }
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true, message: error.message };
  }
  componentDidCatch(error, errorInfo) {
    // You can use your own error logging service here
    // captureException(new Error(error + 'test  sss'));
    console.log({ error, errorInfo });
  }
  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="lg:pt-10 pb-5 lg:px-9 lg:m-10 flex flex-col justify-center items-center lg:border-[1px] border-[#E8E8E8] lg:bg-white">
          <div className="flex items-center justify-center mb-4">
            <MdOutlineErrorOutline className="w-8 h-8 text-burnt-orange" />
            <h1 className="font-bold text-[#1A191E] font-primary text-18 w-full ml-2">
              There was an error loading this section.{' '}
            </h1>
          </div>
          <pre className="text-12 block mb-5 border p-4 rounded-xl border-grey-lighter">
            <code>{this.state.message}</code>
          </pre>

          <Button
            type="small"
            onClick={() => this.setState({ hasError: false })}
          >
            Try again?
          </Button>
        </div>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export default ErrorBoundary;
