import Link from '@components/common/link';
import Logo from '@components/common/logo';
import Config from './config';
import {
  FaFacebookSquare,
  FaInstagram,
  FaLinkedin,
  FaPinterest,
} from 'react-icons/fa';
interface Props {
  user: any;
}

const Footer = ({ user }: Props) => {
  return (
    <footer className="flex flex-col items-center bg-royal-blue-dark text-white p-6 lg:items-end lg:px-14 lg:py-4">
      <div className="flex flex-col items-center lg:w-full lg:flex-row lg:justify-between lg:mt-6">
        <Link className="text-white" href="https://www.tryselfmade.com">
          <Logo className="hover:text-white w-[100px] h-[19px]" />
        </Link>
        <div className="flex flex-col items-center text-14 mt-12 gap-y-2 lg:flex-row lg:mt-0 lg:gap-x-8">
          {user?.id ? (
            <>
              <Link
                className="text-white font-medium"
                href="https://app.tryselfmade.com"
              >
                Dashboard
              </Link>
              <Link
                className="text-white font-medium"
                href="https://app.tryselfmade.com/settings"
              >
                Settings
              </Link>
              <Link
                className="text-white font-medium"
                target="_blank"
                rel="noreferrer"
                href={Config.faqLink}
              >
                Support
              </Link>
            </>
          ) : (
            <>
              <Link
                className="text-white font-medium hidden lg:block"
                href="https://www.tryselfmade.com"
              >
                Home
              </Link>
              <Link
                className="text-white font-medium"
                href="https://www.brit.co/privacy/"
                target="_blank"
              >
                Privacy
              </Link>
              <Link
                className="text-white font-medium"
                href="https://www.brit.co/terms-of-use/"
                target="_blank"
              >
                Terms of Use
              </Link>
              <Link
                className="text-white font-medium"
                target="_blank"
                rel="noreferrer"
                href={Config.faqLink}
              >
                Support
              </Link>
            </>
          )}
        </div>
        <div className="flex items-center mt-12 gap-x-8 lg:mt-0 lg:gap-x-6">
          <Link
            className="text-white"
            href="https://www.instagram.com/beselfmade"
            target="_blank"
            rel="noreferrer"
          >
            <FaInstagram className="h-[24px] w-[24px]" />
          </Link>
          <Link
            className="text-white"
            href="https://www.facebook.com/tryselfmade"
            target="_blank"
            rel="noreferrer"
          >
            <FaFacebookSquare className="h-[24px] w-[24px]" />
          </Link>
          <Link
            className="text-white"
            href="https://www.pinterest.com/brit/_created"
            target="_blank"
            rel="noreferrer"
          >
            <FaPinterest className="h-[24px] w-[24px]" />
          </Link>
          <Link
            className="text-white"
            href="https://www.linkedin.com/company/tryselfmade/"
            target="_blank"
            rel="noreferrer"
          >
            <FaLinkedin className="h-[24px] w-[24px]" />
          </Link>
        </div>
      </div>
      <div className="mt-3 lg:mt-2">
        <span className="text-12 font-secondary font-regular antialiased lg:text-small">
          ©{new Date().getFullYear()} Brit Media, Inc.
        </span>
      </div>
    </footer>
  );
};

export default Footer;
